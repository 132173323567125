<template>
  <div class="datacenter">
    <Header back="返回" title="数据中心" index="首页" titleOne="数据中心" beforeTitle="新增/编辑" />
    <div class="content">
      <div class="dataleft">
        <el-input v-model="dataParams.title" placeholder="请输入数据标题" style="margin-bottom: 20px"></el-input>
        <!-- <h2 v-if="meber_id" style="margin-bottom: 20px">撰写人：{{ true_name }}</h2> -->
        <div class="datatitle">
          <h2 v-if="meber_id">撰写人：{{dataParams.true_name }}</h2>
          <h2 v-if="meber_id">更新时间：{{dataParams.update_time | fmtdate}}</h2>
          <div class="datatype">
            数据类型：
            <el-radio-group v-model="radio" @change="dataChange">
              <el-radio :label="1">公用数据</el-radio>
              <el-radio :label="0">我的数据</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 富文本编辑框 -->
        <div id="about" ref="about" class="about" v-loading="editorLoading">
          <editor :content.sync="dataParams.content"></editor>
        </div>
      </div>
      <div class="dataright" v-loading="loading" element-loading-text="附件加载中···">
        <el-upload
          class="upload-demo"
          :action="UploadUrl()"
          :http-request="httpRequest"
          :before-upload="beforeAvatarUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :on-exceed="handleExceed"
          :file-list="fileList"
          :limit="10"
        >
          <el-button type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">上传txt/jpg/png/doc/xls/pdf/zip文件，单个文件不超过10mb</div>
        </el-upload>
      </div>
      <div class="clear"></div>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";

import Editor from "@/components/tinymce";
export default {
  components: {
    Editor
  },
  data () {
    return {
      editorLoading: true,
      btnLoading: false,//提交按钮动画
      loading: false,
      radio: "",
      dataParams: {
        title: "",
        update_time: "",
        true_name: "",
        content: "",
      },
      editorOption: {},
      isDisable: false,
      userSite: site.userSite,
      fileList: [],
      id: "",
      true_name: "",
      model: "",
      // model_id: "",
      fileid: "",
      fileId: [],
      actionUrl: "",
      meber_id: "",
      mydataParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
        type: "personal",
      },
    };
  },
  computed: {
    // meber_id () {
    //   return this.$route.query.data.id;
    // },
  },
  watch: {
  },
  created () {
    if (this.$route.query.data) {
      this.meber_id = this.$route.query.data.id
      this.radio = this.$route.query.data.status
      this.loading = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.editorLoading = false
    })
    // const element = this.$refs.about;
    // console.log(element.offsetHeight, 789798797);


    if (this.meber_id) {
      this.axios
        .get("/api/data/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.dataParams = res.data
          this.id = res.data.id;
          this.fileList = res.data.file;
          const fileList = res.data.file;
          setTimeout(() => {
            this.loading = false
          }, 1000)
          var arr = [];
          fileList.forEach((item) => {
            arr.push(item.id);
          });
          var file_id = arr.toString();
        });
    }
  },
  methods: {
    dataChange () {
      console.log(this.dataParams.radio);
    },
    onSubmit () {
      if (this.radio == 0 || this.radio == 1 && this.dataParams.title && this.dataParams.content) {
        this.btnLoading = true
        this.axios
          .post("/api/data/store", {
            title: this.dataParams.title,
            update_time: this.dataParams.update_time,
            true_name: this.dataParams.true_name,
            status: this.radio,
            content: this.dataParams.content,
            id: this.id,
            file_ids: this.fileId.toString(),
          })
          .then((res) => {
            console.log(res);
            this.$router.push("/data/index");
            this.$message({
              type: "success",
              message: "保存成功!",
            });
            this.btnLoading = false
            this.isDisable = true;
            this.$parent.activeName = "second";
            this.$store.dispatch("getMydataList", this.mydataParams);
          })
          .catch((res) => {
            console.log("err:", res);
            this.btnLoading = false
          });
      } else {
        this.$message({
          type: "warning",
          message: '请完善信息'
        })
      }
    },

    httpRequest (param) {
      let file = param.file;
      console.log({
        file,
      });
      const form = new FormData();
      console.log(form);
      form.append("file", file);
      form.append("model", "data");
      // form.append("model_id", this.meber_id);
      this.upload("/api/file/upload_file", form).then((res) => {
        this.actionUrl = res.data.url;
        console.log(res.data.url);
        this.fileId.push(res.data.id);
        console.log(this.fileId);
        this.$message({
          type: 'success',
          message: res.message
        })
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    UploadUrl: function () {
      return "userSite +this.actionUrl";
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },

    handlePreview (file) {
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.href = this.userSite + file.url;
      a.target = '_blank';
      a.dispatchEvent(event);
      console.log(file);
    },
    handleExceed (files, fileList) {
      this.$message.warning(
        `本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定删除 ${file.name}？`);
    },
    handleRemove (file) {
      console.log(file);
      this.axios.delete("/api/file/delete_file", {
        params: {
          id: file.id,
        },
      });
      this.$message({
        type: "success",
        message: "删除成功!",
      });
    },
  },
};
</script>

<style scoped>
.dataleft {
  width: 65%;
  float: left;
  border-right: 1px solid #e1e2eb;
  padding-right: 2%;
  margin-right: 2%;
}

.dataleft h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 14px;
  color: #000000;
}

.dataleft h2 {
  font-size: 14px;
  font-weight: 400;
  color: #a6abc7;
  margin: 0;
}

.dataright {
  width: 30%;
  float: right;
}

.el-upload__tip {
  color: #a6abc7;
  margin-bottom: 10px;
}
.datatitle {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.datatype {
  color: #a6abc7;
}
div/deep/.ql-editor {
  height: 300px !important;
}
div/deep/.el-upload-list__item-name {
  /*1. 先强制一行内显示文本*/
  white-space: nowrap;
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
.ql-snow .ql-editor img {
  max-width: 30%;
}
</style>
